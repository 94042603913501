<template>
	<Dialog :dialog.sync="exportDialog">
		<template v-slot:title> Export {{ title }} </template>
		<template v-slot:body>
			<v-form
				ref="exportForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="exportFilter"
			>
				<v-row>
					<v-col md="12">
						<p class="btx-p m-0">You can export your data from {{ $APPNAME }} in CSV or XLSX format.</p>
					</v-col>
					<v-col md="12">
						<v-radio-group hide-details id="export-type" class="mt-0 pt-0" v-model="export_type" column>
							<v-radio :label="`All ${title}`" value="all"></v-radio>
							<v-radio label="Specific Period" value="specific_period"></v-radio>
						</v-radio-group>
					</v-col>
					<template v-if="export_type == 'specific_period'">
						<v-col md="6">
							<label for="export-from-date" class="btx-label required">From</label>
							<DatePicker
								placeholder="From"
								id="export-from-date"
								v-model="export_from_date"
								:rules="[vrules.required(export_from_date, 'From')]"
								:class="{
									required: !export_from_date,
								}"
							></DatePicker>
						</v-col>
						<v-col md="6">
							<label for="export-to-date" class="btx-label required">To</label>
							<DatePicker
								placeholder="To"
								id="export-to-date"
								v-model="export_to_date"
								:minDate.sync="export_from_date"
								:rules="[vrules.required(export_to_date, 'To')]"
								:class="{
									required: !export_to_date,
								}"
							></DatePicker>
						</v-col>
					</template>
					<v-col md="12">
						<label for="export-as" class="btx-label required">Export As</label>
						<v-radio-group hide-details id="export-as" class="mt-2 pt-0" v-model="export_as" column>
							<v-radio label="XLSX (Microsoft Excel)" value="xlsx"></v-radio>
							<v-radio label="CSV (Comma Separated Value)" value="csv"></v-radio>
						</v-radio-group>
					</v-col>
					<!-- <v-col md="8">
						<label for="export-password" class="btx-label">Password to protect the file</label>
						<TextInput
							hide-details
							id="export-password"
							placeholder="Enter password"
							v-model="export_password"
						></TextInput>
					</v-col> -->
					<v-col md="12">
						<p class="btx-p m-0">
							<b>NOTE:</b> You can export only the first 10,000 rows. If you have more rows, please
							initiate a backup for the data in your {{ $APPNAME }} organization, and download it.
							<a href="">Backup Your Data</a>
						</p>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="exportFilter">
				{{ exportButton }}
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/DatePicker.vue";
//import TextInput from "@/view/components/TextInput.vue";
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import { map } from "lodash";

export default {
	name: "export-dialog",
	data() {
		return {
			export_as: "xlsx",
			export_type: "all",
			export_password: null,
			export_from_date: null,
			export_to_date: null,
			export_valid: true,
			exportData: [],
		};
	},
	props: {
		exportDialog: {
			type: Boolean,
			default: false,
		},
		current: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
	},
	methods: {
		exportFilter() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.exportForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.exportForm.validate()) {
				return false;
			}

			let filter = {
				startDate: _this.export_from_date || null,
				endDate: _this.export_to_date || null,
				extensions: _this.export_as,
				search: _this.search,
			};

			_this.exportAsset(filter);

			_this.$emit("close", true);
		},
		exportAsset(data) {
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + this.endpoint;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			const { query } = this.$route;

			if (data.startDate != null && data.endDate != null) {
				downloadURL.searchParams.append("start_date", data.startDate);
				downloadURL.searchParams.append("end_date", data.endDate);
			}
			if (data.extensions) {
				downloadURL.searchParams.append("extensions", data.extensions);
			}
			/* if (this.listingSearch) {
				downloadURL.searchParams.append("search", this.listingSearch);
			} */
			if (query) {
				map(query, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			downloadURL.searchParams.append("token", token);
			window.open(downloadURL, "_blank");
		},
	},
	components: {
		DatePicker,
		//TextInput,
		Dialog,
	},

	computed: {
		...mapGetters(["listingSearch", "errors"]),
		exportButton() {
			return this.current ? "Export Current View" : "Export";
		},
	},
};
</script>
